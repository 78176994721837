/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import domainProduction from '@globo/shared/domainProduction.js'
import faviconUrl from '@globo/shared/faviconUrl.js'
import { useTranslation } from '@multilocale/react/index.js'
import FooterComponent from '../components/FooterComponent.js'
import NavigationComponent from '../components/NavigationComponent.js'
import PricingSection from '../components/PricingSection.js'
import languages from '../languages.js'

export const Head = ({ locale }) => {
  const { t } = useTranslation(locale)

  const title = t('__brand__')
  const description = t('__pricing_title__')

  return (
    <>
      <title>{`${description} | ${title}`}</title>
      <meta name="description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={faviconUrl} />
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="seat" />
      <meta
        property="og:url"
        content={`https://www.${domainProduction}/${locale}/pricing/`}
      />
      <meta property="og:image" content={faviconUrl} />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={faviconUrl} />
      <link
        rel="canonical"
        href={`https://www.${domainProduction}/${locale}/pricing/`}
      />
      <link
        rel="alternate"
        href={`https://www.${domainProduction}/en/pricing/`}
        hreflang="x-default"
      />
      {languages.map(language => (
        <link
          key={language}
          rel="alternate"
          href={`https://www.${domainProduction}/${language}/pricing/`}
          hreflang={language}
        />
      ))}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org/',
            '@type': 'WebPage',
            '@id': `https://www.${domainProduction}/${locale}/`,
            url: `https://www.${domainProduction}/${locale}/`,
            inLanguage: locale,
            isPartOf: {
              '@id': `https://www.${domainProduction}/${locale}/#website`,
            },
            author: {
              '@id': `https://www.${domainProduction}/#corporation`,
            },
            publisher: {
              '@id': `https://www.${domainProduction}/#corporation`,
            },
            translationOfWork: {
              '@id': `https://www.${domainProduction}/en/#website`,
            },
            '@graph': [
              {
                '@type': 'WebSite',
                '@id': `https://www.${domainProduction}/${locale}/#website`,
                name: 'Waiterio',
                url: `https://www.${domainProduction}/${locale}/#website`,
                inLanguage: locale,
                author: {
                  '@id': `https://www.${domainProduction}/#corporation`,
                },
                publisher: {
                  '@id': `https://www.${domainProduction}/#corporation`,
                },
              },
              {
                '@type': 'Corporation',
                '@id': `https://www.${domainProduction}/#corporation`,
                name: 'Waiterio',
                url: `https://www.${domainProduction}/#corporation`,
                sameAs: [
                  `https://www.facebook.com/${domainProduction}`,
                  'https://www.linkedin.com/company/multilocale',
                ],
              },
            ],
          }),
        }}
      />
    </>
  )
}

export const paths = languages.map(locale => ({
  path: `/${locale}/pricing/`,
  props: {
    locale,
  },
}))

const PricingPage = ({ locale }) => (
  <>
    <NavigationComponent locale={locale} />
    <ErrorBoundary>
      <PricingSection locale={locale} />
    </ErrorBoundary>
    <FooterComponent locale={locale} />
  </>
)

export default PricingPage
