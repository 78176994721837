/* Copyright 2013 - 2024 Waiterio LLC */
import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client' // eslint-disable-line import/extensions
import { BrowserRouter } from 'react-router-dom'
import { initMultilocale } from '@multilocale/react/index.js'
import MultilocaleProvider from '@multilocale/react/MultilocaleProvider.js'
import StageBadge from '@stiloso/components/StageBadge.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import ScrollToTop from '@stiloso/components/ScrollToTop.js'
import allowSubdomainsCrossOrigin from '@monorepo/shared/allowSubdomainsCrossOrigin.js'
import getSentryBrowser from '@monorepo/telemetry/getSentryBrowser.js'
import product from '@globo/shared/product.js'
import GlobalStyles from '@globo/shared/GlobalStyles.js'
import CookiesListener from './session/CookiesListener.js'
import GclidCookie from './session/GclidCookie.js'
import ReferrerCookie from './session/ReferrerCookie.js'
import UtmCampaignCookie from './session/UtmCampaignCookie.js'
import UtmDeviceCookie from './session/UtmDeviceCookie.js'
import UtmSourceCookie from './session/UtmSourceCookie.js'
import UtmTermCookie from './session/UtmTermCookie.js'
import Routes from './Routes.js'
import service from './service.js'

allowSubdomainsCrossOrigin()

initMultilocale({
  organizationId: 'e395dba6a14d8af4c2d35890',
  project: `${product}-${service}`,
})

createRoot(document.getElementById('root')).render(
  <ErrorBoundary>
    <BrowserRouter>
      <MultilocaleProvider>
        <Suspense>
          <ScrollToTop />
          <GlobalStyles />
          <Routes />
          <CookiesListener />
          <GclidCookie />
          <ReferrerCookie />
          <UtmCampaignCookie />
          <UtmDeviceCookie />
          <UtmSourceCookie />
          <UtmTermCookie />
          <StageBadge />
        </Suspense>
      </MultilocaleProvider>
    </BrowserRouter>
  </ErrorBoundary>,
)

getSentryBrowser()
