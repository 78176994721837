/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import rotationString from '@stiloso/styles/rotationString.js'
import { Link } from 'react-router-dom'

const spinner = css`
  margin: 0px auto;
  display: block;

  border-radius: 50%;
  width: 8em;
  height: 8em;
  font-size: 4px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid var(--color-background);
  border-right: 1.1em solid var(--color-background);
  border-bottom: 1.1em solid var(--color-background);
  border-left: 1.1em solid var(--color-primary-dark, gray);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: rotation 1.1s infinite linear;
  animation: rotation 1.1s infinite linear;
  ${rotationString};
`

const spinnerDanger = css`
  ${spinner};
  border-left: 1.1em solid var(--color-red-dark, gray);
`

const spinnerSuccess = css`
  ${spinner};
  border-left: 1.1em solid var(--color-green-dark, gray);
`

const spinners = {
  primary: spinner,
  secondary: spinner,
  danger: spinnerDanger,
  success: spinnerSuccess,
}

const buttonLarge = css`
  font-size: 16px;
  padding: 8px 16px;
  height: 48px;
  line-height: 30px;
`
const buttonSmall = css`
  font-size: 14px;
  padding: 6px 12px;
  height: 36px;
  line-height: 18px;
`

const buttonPrimary = css`
  max-width: 100%;
  margin: 0;
  border: 0;
  font-weight: 600;
  text-transform: capitalize;
  background-color: var(--color-primary);
  color: white;
  outline: none;
  white-space: nowrap;
  overflow: ellipsis;
  cursor: pointer;
  transition: 0.125s background-color ease;
  border-radius: 4px;

  @media (hover: hover) {
    &:not(.disabled):hover {
      background-color: var(--color-primary-dark);
    }
  }

  &:not(.disabled):focus-visible {
    background-color: var(--color-primary-dark);
  }

  &:not(.disabled):active {
    background-color: var(--color-primary-darker);
  }
`

const buttonSecondary = css`
  ${buttonPrimary}
  background-color: #ffffff;
  color: var(--color-base);

  @media (hover: hover) {
    &:not(.disabled):hover {
      background-color: var(--color-hover);
    }
  }

  &:not(.disabled):focus-visible {
    background-color: var(--color-focus);
  }

  &:not(.disabled):active {
    background-color: var(--color-active);
  }
`

const buttonDanger = css`
  ${buttonPrimary}
  background-color: var(--color-red);
  color: white;

  @media (hover: hover) {
    &:not(.disabled):hover {
      background-color: var(--color-red-dark);
    }
  }

  &:not(.disabled):focus-visible {
    background-color: var(--color-red-dark);
  }

  &:not(.disabled):active {
    background-color: var(--color-red-darker);
  }
`

const buttonSuccess = css`
  ${buttonPrimary}
  background-color: var(--color-green);
  color: white;

  @media (hover: hover) {
    &:not(.disabled):hover {
      background-color: var(--color-green-dark);
    }
  }

  &:not(.disabled):focus-visible {
    background-color: var(--color-green-dark);
  }

  &:not(.disabled):active {
    background-color: var(--color-green-darker);
  }
`

const primaryDisabledButton = css`
  background-color: var(--color-primary-light);
  pointer-events: none;
`

const dangerDisabledButton = css`
  ${primaryDisabledButton}
  background-color: var(--color-red-light);
`

const successDisabledButton = css`
  ${primaryDisabledButton}
  background-color: var(--color-green-light);
`

const disabledButtons = {
  primary: primaryDisabledButton,
  danger: dangerDisabledButton,
  success: successDisabledButton,
}

const appearances = {
  primary: buttonPrimary,
  secondary: buttonSecondary,
  danger: buttonDanger,
  success: buttonSuccess,
}

const sizes = {
  large: buttonLarge,
  small: buttonSmall,
}

/* eslint-disable react/button-has-type */
const Button = ({
  children,
  appearance = 'primary',
  type = 'button',
  isLoading,
  fullWidth,
  onClick,
  to,
  href,
  className,
  size = 'large',
}) => {
  if (to) {
    return (
      <Link
        css={[
          appearances[appearance],
          fullWidth && { display: 'block', width: '100%', textAlign: 'center' },
          sizes[size],
        ]}
        className={className}
        to={to}
      >
        {children}
      </Link>
    )
  } else if (href) {
    return (
      <a
        css={[
          appearances[appearance],
          fullWidth && { display: 'block', width: '100%', textAlign: 'center' },
          sizes[size],
        ]}
        className={className}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    )
  } else {
    return (
      <button
        css={[
          appearances[appearance],
          fullWidth && { width: '100%' },
          sizes[size],
          isLoading && [disabledButtons[appearance], { height: 48 }],
        ]}
        className={className}
        type={type}
        disabled={isLoading}
        onClick={onClick}
      >
        {!isLoading ? children : <span css={spinners[appearance]} />}
      </button>
    )
  }
}

export default Button
