/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint max-len: 0 */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'

const iconDefault = css`
  fill: none;
  stroke: white;
  stroke-width: 2;
  transition: stroke 0.2s;
  cursor: pointer;

  :hover {
    stroke: #ffa503;
  }
`

const logoDark = css`
  cursor: pointer;
  display: flex;
  align-items: center;

  span {
    font-size: 20px;
    letter-spacing: 2px;
    color: var(--color-base);
    margin-left: 8px;
    transition: color 0.125s ease;
    user-select: none;
  }

  svg {
    stroke: var(--color-base);
  }

  .bubble {
    fill: var(--color-primary);
  }
  .questionMark {
    fill: #ffffff;
  }

  @media (hover: hover) {
    :hover {
      svg {
        stroke: var(--color-secondary);
      }
      span {
        color: var(--color-secondary);
      }
      .bubble {
        fill: var(--color-secondary);
      }
    }
  }

  :active {
    svg {
      stroke: var(--color-secondary-dark);
    }
    span {
      color: var(--color-secondary-dark);
    }
    .bubble {
      fill: var(--color-secondary-dark);
    }
  }
`

const logoLight = css`
  ${logoDark}
  span {
    color: white;
  }
  svg {
    stroke: white;
  }
  .bubble {
    fill: #ffffff;
  }
  .questionMark {
    fill: var(--color-primary);
  }
`

const IconGlobo = props => {
  const { width = 32, height = 32, light, onClick } = props
  const dataTestId = props['data-testid'] || 'icon-globo'

  return (
    <span css={light ? logoLight : logoDark}>
      <svg
        css={iconDefault}
        width={width}
        height={height}
        onClick={onClick}
        data-testid={dataTestId}
        viewBox="0 0 400 400"
      >
        <circle className="bubble" cx="195.76" cy="193" r="138" />
        <path
          className="bubble"
          d="M310.31,239.73l-32.05,49.8c-2.4,3.74,0.11,8.69,4.55,8.95l51.54,3.03c4.18,0.25,7.24-3.88,5.79-7.81
          l-19.49-52.83C318.99,236.37,312.91,235.71,310.31,239.73z"
        />
        <path
          className="questionMark"
          d="M151.73,154.62c-10.96-4.81-14.74-18.5-7.76-28.22c8.14-11.33,22.97-23.26,49.33-24.22
              c33.94-1.25,65.07,19.8,66.23,51.71c0.81,22.17-15.2,36.36-36.58,46.43c-5.02,1.25-6.92,7.46-7.46,13.93
              c-0.8,9.35-8.58,16.57-17.96,16.92l0,0c-10.34,0.38-19.03-7.69-19.41-18.03l-0.22-5.94c-0.37-9.95-0.22-18.11,9.72-25.06
              c13.48-9.33,26.34-10.49,25.76-26.55c-0.41-10.86-8.83-18.25-19.01-17.88c-9.29,0.34-15.69,4.99-19.87,10.75
              C169.3,155.61,159.82,158.18,151.73,154.62L151.73,154.62z M220.67,270.36c0.42,11.45-8.51,21.08-20.16,21.5
              c-11.45,0.42-21.08-8.51-21.5-19.97c-0.43-11.64,8.5-21.25,19.97-21.67C210.63,249.78,220.24,258.72,220.67,270.36z"
        />
      </svg>
      <span>Globo</span>
    </span>
  )
}

export default IconGlobo
