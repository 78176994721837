/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'
import getCookie from '@monorepo/shared/getCookie.js'
import blogId from '@globo/shared/blogId.js'
import domainProduction from '@globo/shared/domainProduction.js'
import facebookHandle from '@globo/shared/facebookHandle.js'
import faviconUrl from '@globo/shared/faviconUrl.js'
import linkedinHandle from '@globo/shared/linkedinHandle.js'
import getAppUrl from '@globo/shared/getAppUrl.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import BannerSection from '@stiloso/sections/BannerSection.js'
import { useTranslation } from '@multilocale/react/index.js'
import WhySection from '@stiloso/sections/WhySection.js'
import FactsSection from '@stiloso/sections/FactsSection.js'
import HowSection from '@stiloso/sections/HowSection.js'
import QuestionsSection from '@stiloso/sections/QuestionsSection.js'
import BlogSection from '@stiloso/sections/BlogSection.js'
import TestimonialSection from '@stiloso/sections/TestimonialSection.js'
import IconCart from '@stiloso/icons/IconCart.js'
import IconCloudSearch from '@stiloso/icons/IconCloudSearch.js'
import IconPapillon from '@stiloso/icons/IconPapillon.js'
import IconTranslate from '@stiloso/icons/IconTranslate.js'
import IllustrationInformation from '@stiloso/components/IllustrationInformation.js'
import IllustrationLogin from '@stiloso/components/IllustrationLogin.js'
import IllustrationManWorkingOnComputer from '@stiloso/components/IllustrationManWorkingOnComputer.js'
import IllustrationTranslate from '@stiloso/components/IllustrationTranslate.js'
import product from '../../product.js'
import FooterComponent from '../../components/FooterComponent.js'
import NavigationComponent from '../../components/NavigationComponent.js'
import PricingSection from '../../components/PricingSection.js'
import language2paths from './language2paths.js'

export const Head = ({ locale }) => {
  const { t } = useTranslation(locale)

  const title = t('__brand__', product)
  const bannerTitle = t('__livechat_banner_title__')
  const bannerSubtitle = t('__livechat_banner_subtitle__')
  const description = `${bannerTitle} | ${bannerSubtitle}`
  const titleSEO = t('__livechat_head_title__', `${description} | ${title}`)
  const descriptionSEO = t('__livechat_head_meta_description__', description)

  return (
    <>
      <title>{titleSEO}</title>
      <meta name="description" content={descriptionSEO} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={faviconUrl} />
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="article" />
      <meta
        property="og:url"
        content={`https://www.${domainProduction}/${locale}/${language2paths[locale]}/`}
      />
      <meta property="og:image" content={faviconUrl} />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={faviconUrl} />
      <link
        rel="canonical"
        href={`https://www.${domainProduction}/${locale}/${language2paths[locale]}/`}
      />
      <link
        rel="alternate"
        href={`https://www.${domainProduction}/en/${language2paths.en}/`}
        hreflang="x-default"
      />
      {Object.keys(language2paths).map(language => (
        <link
          key={language}
          rel="alternate"
          href={`https://www.${domainProduction}/${language}/${language2paths[locale]}/`}
          hreflang={language}
        />
      ))}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org/',
            '@type': 'WebPage',
            '@id': `https://www.${domainProduction}/${locale}/`,
            url: `https://www.${domainProduction}/${locale}/`,
            inLanguage: locale,
            isPartOf: {
              '@id': `https://www.${domainProduction}/${locale}/#website`,
            },
            author: {
              '@id': `https://www.${domainProduction}/#corporation`,
            },
            publisher: {
              '@id': `https://www.${domainProduction}/#corporation`,
            },
            translationOfWork: {
              '@id': `https://www.${domainProduction}/en/#website`,
            },
            '@graph': [
              {
                '@type': 'WebSite',
                '@id': `https://www.${domainProduction}/${locale}/#website`,
                name: 'Waiterio',
                url: `https://www.${domainProduction}/${locale}/#website`,
                inLanguage: locale,
                author: {
                  '@id': `https://www.${domainProduction}/#corporation`,
                },
                publisher: {
                  '@id': `https://www.${domainProduction}/#corporation`,
                },
              },
              {
                '@type': 'Corporation',
                '@id': `https://www.${domainProduction}/#corporation`,
                name: 'Waiterio',
                url: `https://www.${domainProduction}/#corporation`,
                sameAs: [
                  facebookHandle &&
                    `https://www.facebook.com/${facebookHandle}`,
                  linkedinHandle &&
                    `https://www.linkedin.com/company/${linkedinHandle}}`,
                ].filter(_ => _),
              },
            ],
          }),
        }}
      />
    </>
  )
}

export const paths = Object.keys(language2paths).map(locale => ({
  path: `/${locale}/${language2paths[locale]}/`,
  props: {
    locale,
  },
}))

const LivechatPage = ({ locale }) => {
  const { t } = useTranslation(locale)
  const appUrl = getAppUrl()

  const whySubsections = [
    {
      title: t('__livechat_why_1_title__', t('__home_why_1_title__')),
      content: t('__livechat_why_1_content__', t('__home_why_1_content__')),
    },
    {
      title: t('__livechat_why_2_title__', t('__home_why_2_title__')),
      content: t('__livechat_why_2_content__', t('__home_why_2_content__')),
    },
    {
      title: t('__livechat_why_3_title__', t('__home_why_3_title__')),
      content: t('__livechat_why_3_content__', t('__home_why_3_content__')),
    },
    {
      title: t('__livechat_why_4_title__', t('__home_why_4_title__')),
      content: t('__livechat_why_4_content__', t('__home_why_4_content__')),
    },
  ]

  const facts = [
    {
      content: t('__livechat_facts_1__', t('__home_facts_1__')),
      Icon: IconCart,
    },
    {
      content: t('__livechat_facts_2__', t('__home_facts_2__')),
      Icon: IconTranslate,
    },
    {
      content: t('__livechat_facts_3__', t('__home_facts_3__')),
      Icon: IconCloudSearch,
    },
  ]

  const howSubsections = [
    {
      title: t('__livechat_how_1_title__', t('__home_how_1_title__')),
      content: t('__livechat_how_1_content__', t('__home_how_1_content__')),
      Illustration: IllustrationLogin,
    },
    {
      title: t('__livechat_how_2_title__', t('__home_how_2_title__')),
      content: t('__livechat_how_2_content__', t('__home_how_2_content__')),
      Illustration: IllustrationInformation,
    },
    {
      title: t('__livechat_how_3_title__', t('__home_how_3_title__')),
      content: t('__livechat_how_3_content__', t('__home_how_3_content__')),
      Illustration: IllustrationTranslate,
    },
  ]

  const testimonials = [
    {
      name: 'Waiterio',
      logo: <IconPapillon width="44" height="44" style={{ fill: 'white' }} />,
      color: '#3f92d2',
      content: t('__livechat_testimonials_1__', t('__home_testimonials_1__')),
      url: 'https://www.waiterio.com',
    },
  ]

  const documents = [
    {
      title: t('__livechat_faqs_1_title__', t('__home_faqs_1_title__')),
      content: t('__livechat_faqs_1_content__', t('__home_faqs_1_content__')),
    },
    {
      title: t('__livechat_faqs_2_title__', t('__home_faqs_2_title__')),
      content: t('__livechat_faqs_2_content__', t('__home_faqs_2_content__')),
    },
    {
      title: t('__livechat_faqs_3_title__', t('__home_faqs_3_title__')),
      content: t('__livechat_faqs_3_content__', t('__home_faqs_3_content__')),
    },
    {
      title: t('__livechat_faqs_4_title__', t('__home_faqs_4_title__')),
      content: t('__livechat_faqs_4_content__', t('__home_faqs_4_content__')),
    },
    {
      title: t('__livechat_faqs_5_title__', t('__home_faqs_5_title__')),
      content: t('__livechat_faqs_5_content__'),
    },
  ]

  let showDashboard = !!getCookie(product + 'AccessToken')

  return (
    <>
      <NavigationComponent locale={locale} />
      <ErrorBoundary>
        <BannerSection
          title={t('__livechat_banner_title__')}
          subtitle={t('__livechat_banner_subtitle__')}
          buttonLabel={t(showDashboard ? 'Go to the App' : 'Sign up')}
          buttonOnClick={() => {
            window.location.href = showDashboard ? appUrl : `${appUrl}/signup`
          }}
        />
        <WhySection
          title={t('__livechat_why_title__')}
          subsections={whySubsections}
          illustration={<IllustrationManWorkingOnComputer />}
        />

        <FactsSection title={t('__livechat_facts_title__')} facts={facts} />

        <HowSection
          title={t('__livechat_how_title__')}
          subsections={howSubsections}
        />

        <TestimonialSection
          title={t('__livechat_testimonials_title__')}
          testimonials={testimonials}
        />

        <PricingSection locale={locale} />

        <QuestionsSection
          title={t('__livechat_faqs_title__')}
          locale={locale}
          documents={documents}
        />

        <BlogSection blogId={blogId} locale={locale} />
      </ErrorBoundary>

      <FooterComponent locale={locale} />
    </>
  )
}

export default LivechatPage
